<template>
  <div class="login-container">
    <div class="img-container">
      <img src="@/assets/imgs/landscape.jpg" alt="" />
      <div class="overlay"></div>
      <div class="booking-text-login">Bookingiraq</div>
      <div class="bottom-text">
        اكبر منصة لحجز الفنادق في العراق. من خلال BookingIraq يمكنك حجز الفنادق من
        المدن العراقية المفضلة لديك بكل سهولة واريحية. كل ما عليك فعله هو تحديد الوجهة
        الخاصة بك, عدد الاشخاص ومدة الاقامة ونحن نقوم بالباقي
      </div>
    </div>
    <div class="right-side" dir="rtl" style="margin-top: 12rem">
      <div
        style="font-size: 26px; color: #ffb325; font-weight: bold; margin-bottom: 2rem"
      >
      أعادة تعيين الرمز السري
      </div>
      <div class="mb-12">
        لاعادة تعيين الرمز السري يرجى ادخال بريدك الالكتروني ليتسنى لنا ارسال الرمز التاكيدي لبريدك الالكتروني
      </div>
      <div>
        <v-locale-provider rtl>
          <div class="mb-3">البريد الالكتروني</div>
          <v-text-field
            placeholder="البريد الالكتروني"
            append-inner-icon="mdi-email"
            variant="outlined"
          ></v-text-field>
        </v-locale-provider>
      </div>
      <div class="show-more-button">
        <router-link active-class="active" class="hover:text-red duration-500" to="/password-recovery2">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
          >
          ارسال الرمز التأكيدي
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  /* eslint-disable */
  components: {},
  data() {
    return {
        visible:false
    }
  },
};
</script>

<style>
.login-container {
  font-family: "Cairo", sans-serif;

  width: 70%;
  height: 100vh;
  margin: auto;
  display: flex;
  /* border: 1px solid #000; */
}
.img-container {
  width: 50%;
  height: 100%;
  position: relative;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.right-side {
  width: 50%;
  margin: auto;
  padding: 2rem;
  /* display:flex; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 104, 175, 0);
  background: linear-gradient(to bottom, #003b7d, rgba(0, 145, 255, 0.4));
  z-index: 5;
}
.booking-text {
  position: absolute;
  top: 30%;
  left: 35%;
  color: #fff;
  font-size: 30px;
  z-index: 15;
}
.bottom-text {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 10%;
  color: #fff;
  font-size: 20px;
  z-index: 15;
  margin: auto;
  text-align: center;
}
@media (max-width: 1400px) {
  .login-container {
    width: 80%;
    height: 100vh;
    margin: auto;
    /* border: 1px solid #000; */
  }
  .booking-text {
    left: 30%;
  }
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.sign-in-with {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.sign-in-with div {
    text-align: center;
    cursor:pointer;
  }
@media (max-width: 1400px) {
  .sign-in-with {
    flex-direction: column;
    /* margin:auto; */
    align-items: center;
    gap: 20px;
  }
  .sign-in-with div {
    width: 100%;
    text-align: center;
    cursor:pointer;
  }
}
</style>
