
import $http from "@/plugins/axios.js"


export default {
    state:{
        userInfo: localStorage.getItem("user_Info") ? JSON.parse(localStorage.getItem("user_Info")) : null,
        user_permissions:JSON.parse(localStorage.getItem('user_permissions')) || []
    },
    getters:{
        userInfo: state =>  state.userInfo,
        otpInfo: state =>  state.otpInfo,
        user_permissions: state =>  state.user_permissions,
    },
    actions:{
        async register({commit},data){
            const response = await $http.post('/auth/register',data)
            localStorage.setItem("user_Info", JSON.stringify(response.data));
            commit('SET_USER', response.data) 
        },
        async login({commit},data){
            const response = await $http.post('/auth/login',data)
            localStorage.setItem("user_Info", JSON.stringify(response.data));
            commit('SET_USER', response.data) 
        },
        async loginGuest({commit}){
            const response = await $http.post('/auth/guest-user')
            localStorage.setItem("user_Info", JSON.stringify(response.data));
            commit('SET_USER', response.data) 
        },
        async verifyOtp({commit},data){
            const response = await $http.post('/auth/verify-otp',data)
            commit('SET_OTP', response.data) 
        },
        async logOut({ commit }) {
            await $http.post('/auth/logout');
            localStorage.removeItem("user_Info");
            commit('SET_USER', {});  // Clear the user information from Vuex
        },
        async updateAuthUser({commit}, user) {
            await $http.post(`/auth/update-profile`, user);
            commit("UPDATE_USER_DETAILS", user)
        },      
        async updateAuthUserSubscriptionID(_ctx, user) {
            await $http.post(`/auth/update-profile`, user);
        },      
        async updateAuthPassword(_ctx, user) {
            await $http.post(`/auth/update-profile`, user);
        },      
    },
    mutations:{
        SET_USER(state,data){
            state.userInfo = data
        },
        UPDATE_USER_DETAILS(state,data){
            state.userInfo.userAuth = data
            localStorage.setItem("user_Info", JSON.stringify(state.userInfo))
        },
        SET_OTP(state,data){
            state.otpInfo = data
        },
        SET_PERMISSIONS(state,data){
            state.user_permissions = data
        }
    }
}