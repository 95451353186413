<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="container shadow">
    <div class="content">
      <div class="slider-text-top">{{ $t('message.contactHeader') }}</div>
      <div>
        <div class="channels-to-contact" :style="locale === 'ar' || locale === 'kr' ?'':'flex-direction:row-reverse'" >
          <div class="contact-channel">
            <v-icon size="40px" icon="mdi-email-outline"></v-icon>
            <span style="font-weight: 800">{{ $t('message.email') }}</span>
            <a href="mailto:info@bookingiraq.com" style="font-size: 14px">info@bookingiraq.com</a>
          </div>
          <div class="contact-channel">
            <v-icon size="40px" icon="mdi-map-marker-outline"></v-icon>
            <span style="font-weight: 800">{{ $t('message.locationDescription') }}</span>
            <span>
              <div>{{ $t('message.office') }}</div>
              <div style="font-weight: 800; margin-top: 1rem">{{ $t('message.location') }}</div>
              <div>{{ $t('message.locationDetails') }}</div>
            </span>
          </div>
          <div class="contact-channel">
            <v-icon size="40px" icon="mdi-phone-outline"></v-icon>
            <span style="font-weight: 800">{{ $t('message.phone') }}</span>
            <span dir="ltr">+971544679144</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "ContactInfo",
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
};
</script>

<style scoped>
.container {
  font-family: "Cairo", sans-serif;
  position: relative;
  top: -70px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  align-items: flex-start;
  background-color: #fff;
  padding: 2rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.content {
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 25px;
}
.slider-text-top {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb325;
  font-size: 1.5em;
  text-align: center;
  z-index: 10;
  margin-bottom: 2rem;
}
.channels-to-contact {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.contact-channel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-left: 2px #ddd solid;
  padding: 1rem 2rem;
  flex: 1 1 30%;
}
.contact-channel:last-child {
  border: none;
}

/* Responsive Design */
@media (max-width: 767px) {
  .channels-to-contact {
    flex-direction: column;
    gap: 20px;
  }
  .contact-channel {
    border: none;
    padding: 1rem 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .channels-to-contact {
    flex-direction: column;
    gap: 20px;
  }
  .contact-channel {
    border: none;
    padding: 1rem 0;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 70%;
  }
  .contact-channel {
    padding: 1rem 5rem;
  }
}
</style>
