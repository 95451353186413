<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="social-media-container shadow">
    <div class="top-typing">{{ $t('message.contactMethods') }}</div>
    <div class="icon-buttons">
      <div class="buttons-container">
        <i class="fa-solid fa-envelope"></i>
      </div>
      <div class="buttons-container">
        <i class="fa-brands fa-whatsapp"></i>
      </div>
      <div class="buttons-container">
        <i class="fa-brands fa-instagram"></i>
      </div>
      <div class="buttons-container">
        <i class="fa-brands fa-facebook-f"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { locale } = useI18n();
    return { locale };
  }
};
</script>

<style scoped>
.social-media-container {
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: auto;
  margin-bottom: 5rem;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-typing {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb325;
  font-size: 1.5em;
  text-align: center;
  z-index: 10;
  margin-bottom: 2rem;
  margin: auto;
}
.icon-buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-container {
  background-color: #003b7d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
