<template>
  <div
    :class="[
      { rtl: locale === 'ar' || locale === 'kr' },
      'conainter shadow border-box',
    ]"
  >
    <div class="w-full">
      <div
        :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
        class="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_100px] gap-5 px-10 py-6 border-box"
      >
        <div class="right-field">
          <div
            :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
            class="pb-1"
          >
            <v-icon
              class="mx-1"
              size="large"
              icon="mdi-map-marker-outline"
            ></v-icon>
            <span>{{ $t("message.whereToGo") }}</span>
          </div>
          <v-locale-provider :rtl="locale === 'ar' || locale === 'kr'">
            <v-autocomplete
              variant="outlined"
              density="compact"
              v-model="searchField"
              :items="filteredItems"
              item-title="en_name"
              return-object
              no-filter
              @update:search="getSearch"
              @update:modelValue="showSearch"
            >
              <template v-slot:item="{ item, props }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="
                    item.raw.type === 'hotel' ? 'mdi-bed' : 'mdi-map-marker'
                  "
                  :subtitle="item.raw.type"
                  :title="item.raw.en_name"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-locale-provider>
        </div>
        <div
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          class="left-field"
          style="margin-bottom: 1.45rem"
        >
          <div class="pb-1">
            <v-icon
              class="mx-2"
              size="large"
              icon="mdi-calendar-blank"
            ></v-icon>
            <span>{{ $t("message.durationOfStay") }}</span>
          </div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{}">
              <DatePicker
                type="date"
                range
                :lang="locale === 'ar' ? 'ar-sa' : 'en'"
                dir="rtl"
                v-model:value="dateToShow"
                value-type="YYYY-MM-DD"
                format="YYYY/MM/DD"
                panel-change="day"
                :open="isDatePickerOpen"
                :default-value="new Date()"
                :disabled-date="disablePastDates"
                @open="isDatePickerOpen = true"
              >
                <template #header>
                  <div class="font-bold text-black text-center">
                    {{ $t("message.specifyDate") }}
                  </div>
                </template>
                <template #footer>
                  <button
                    class="bg-[#013B7D] text-white border px-3 py-1 rounded-full text-align-center"
                    @click="changeDate()"
                  >
                    {{ $t("actions.done") }}
                  </button>
                </template>
              </DatePicker>
            </template>
          </v-menu>
        </div>
        <div class="center-field">
          <div
            :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
            class="pb-1"
          >
            <v-icon
              class="mx-2"
              size="large"
              icon="mdi-account-multiple-outline"
            ></v-icon>
            <span>{{ $t("message.numberOfPeople") }}</span>
          </div>
          <v-locale-provider :rtl="isRTL">
            <v-text-field
              density="compact"
              variant="outlined"
              v-model="people_no_text_field"
              @click="peopleNoClick()"
            ></v-text-field>
          </v-locale-provider>
          <v-card ref="peopleCard" class="people-card" v-if="peopleModel">
            <div
              style="
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                border-bottom: 1px solid #eee;
                margin: 1rem;
                padding-bottom: 1rem;
              "
            >
              <div style="font-size: 16px; color: #ffb325">
                {{ $t("message.numberOfPeople") }}
              </div>
              <div @click="closeNoModel()" style="cursor: pointer">
                <button
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
                >
                  {{ $t("actions.done") }}
                </button>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfRooms") }}</div>
              <div style="margin-top: 1rem; z-index: 1000">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="room_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('room_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('room_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfAdults") }}</div>
              <div style="margin-top: 1rem">
                <v-text-field
                  style="padding: 0rem; margin: auto"
                  variant="outlined"
                  density="compact"
                  v-model="adult_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('adult_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('adult_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfChildren") }}</div>
              <div style="margin-top: 1rem">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="children_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('children_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('children_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div v-if="children_no">
              <div
                v-for="(item, index) in children_no"
                :key="index"
                class="input-number"
              >
                <div>{{ $t("message.childAge", { index: index + 1 }) }}</div>
                <div style="margin-top: 1rem">
                  <v-locale-provider :rtl="isRTL">
                    <v-select
                      density="compact"
                      :rtl="isRTL"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17,
                      ]"
                      item-title="ar_name"
                      item-value="id"
                      v-model="child_age[index]"
                      variant="outlined"
                    >
                      <template v-slot:prepend>
                        {{ $t("message.year") }}
                      </template>
                    </v-select>
                  </v-locale-provider>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="flex items-center">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-md px-10 py-2.5 text-center mt-2"
            @click="search()"
          >
            {{ $t("actions.search") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/locale/ar-sa";
import "vue-datepicker-next/locale/en";
import "vue-datepicker-next/index.css";
export default {
  components: { DatePicker },
  name: "SearchBox",
  setup() {
    const { t, locale } = useI18n();
    const isRTL = locale.value === "ar";

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const dateRange = ref([]);
    const dateRangeFull = ref([]);
    const room_no = ref(0);
    const menu = ref(false);
    const adult_no = ref(0);
    const children_no = ref(0);
    const peopleModel = ref("");
    const people_no_text_field = ref("");
    const dateToShow = ref("");
    const governorate = ref("");
    const checkin = ref("");
    const checkout = ref("");
    const todayDate = ref("");
    const governorates = ref([]);
    const searchFieldInputs = ref([]);
    const tomorrowDate = ref("");
    const child_age = ref([]);
    const searchField = ref("");
    const isDatePickerOpen = ref(false);
    const disableDate = ref([]);

    const date = ref(new Date());

    const getDate = () => {
      return "";
    };
    checkin.value = route.query.checkin ?? todayDate.value;
    checkout.value = route.query.checkout ?? tomorrowDate.value;

    if (route.query.checkin && route.query.checkout) {
      dateRange.value = [];
      const currentDateQuery = new Date(route.query.checkin);
      const endDateeQuery = new Date(route.query.checkout);
      dateRange.value.push(new Date(currentDateQuery));
      dateRange.value.push(new Date(endDateeQuery));
      dateToShow.value = [checkin.value, checkout.value];
    } else {
      dateRange.value = [];
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      checkin.value = `${year}-${month}-${day}`;
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const year2 = tomorrow.getFullYear();
      const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      const day2 = tomorrow.getDate().toString().padStart(2, "0");
      checkout.value = `${year2}-${month2}-${day2}`;
      dateRange.value.push(new Date(checkout.value));
      dateRange.value.push(new Date(checkin.value));
      dateToShow.value = [checkin.value, checkout.value];
    }
    if (route.query.room_no) {
      room_no.value = route.query.room_no;
    } else {
      room_no.value = 1;
    }
    if (route.query.adult_no) {
      adult_no.value = route.query.adult_no;
    } else {
      adult_no.value = 1;
    }

    if (route.query.children_no) {
      children_no.value = route.query.children_no;
    } else {
      children_no.value = 0;
    }

    if (
      route.query.room_no ||
      route.query.adult_no ||
      route.query.children_no
    ) {
      people_no_text_field.value = `${room_no.value} ${t("message.room")} - ${
        adult_no.value
      } ${t("message.adult")} - ${children_no.value} ${t("message.child")}`;
    } else {
      people_no_text_field.value = `1 ${t("message.room")} - 1 ${t(
        "message.adult"
      )} - 0 ${t("message.child")}`;
    }

    // Add this debounce function at the top of the setup function
    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };
    const disablePastDates = (date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to the start of today
      return date < today; // Disable dates before today
    };
    const showSearch = async () => {
      if (searchField.value?.type == "hotel") {
        router.push({
          name: "Hotel",
          query: {
            id: searchField.value?.id,
            governorate: governorate.value,
            checkin: checkin.value,
            checkout: checkout.value,
            room_no: room_no.value == 0 ? 1 : room_no.value,
            adult_no: adult_no.value == 0 ? 1 : adult_no.value,
            children_no: children_no.value,
          },
        });
      } else {
        governorate.value = searchField.value?.id;
        router.push({
          query: {
            governorate: searchField.value?.id,
            checkin: checkin.value,
            checkout: checkout.value,
            room_no: room_no.value == 0 ? 1 : room_no.value,
            adult_no: adult_no.value == 0 ? 1 : adult_no.value,
            children_no: children_no.value,
          },
        });
      }
    };
    const getSearch = (search) => {
      if (!search || search.length < 1) {
        store.dispatch("getGovernorates").then((govs) => {
          searchFieldInputs.value = govs.data;
        });
      } else {
        store
          .dispatch("searchHotels", { search: search || "Mos" })
          .then((searchResults) => {
            searchFieldInputs.value = searchResults.data; // Assign the returned data to searchFieldInputs
            getGovernorate();
          })
          .catch((error) => {
            console.error("Error fetching governorates:", error);
          });
      }
    };

    // Create a debounced version of getSearch with a short delay (200ms)
    const debouncedGetSearch = debounce(getSearch, 300);

    const getGovernorate = async () => {
      try {
        if (route.query.governorate || !governorates.value.length) {
          await store.dispatch("getGovernorates");
        }
        governorates.value = store.getters.governorate;
        for (let item in governorates.value) {
          if (route.query.governorate == governorates.value[item].id) {
            searchField.value = governorates.value[item].en_name;
          }
        }
      } catch (error) {
        console.error("Error fetching governorates:", error);
      }
    };

    onMounted(() => {
      getSearch();
      getGovernorate();
      // const today = new Date();
      // const year = today.getFullYear();
      // const month = (today.getMonth() + 1).toString().padStart(2, "0");
      // const day = today.getDate().toString().padStart(2, "0");
      // checkin.value = `${year}-${month}-${day}`;
      // const tomorrow = new Date(today);
      // tomorrow.setDate(tomorrow.getDate() + 1);
      // const year2 = tomorrow.getFullYear();
      // const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      // const day2 = tomorrow.getDate().toString().padStart(2, "0");
      // checkout.value = `${year2}-${month2}-${day2}`;
      // dateRange.value.push(new Date(checkout.value));
      // dateRange.value.push(new Date(checkin.value));
    });

    const disabledBeforeToday = (date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      disableDate.value = date < today;
    };
    const addOne = (field) => {
      if (field === "room_no") {
        room_no.value++;
      } else if (field === "adult_no") {
        adult_no.value++;
      } else if (field === "children_no") {
        child_age.value.push(0);
        children_no.value++;
      }
      setupPeopleNoField();
    };

    const minusOne = (field) => {
      if (field === "room_no" && room_no.value !== 0) {
        room_no.value--;
      } else if (field === "adult_no" && adult_no.value !== 0) {
        adult_no.value--;
      } else if (field === "children_no" && children_no.value !== 0) {
        children_no.value--;
        child_age.value.pop();
      }
      setupPeopleNoField();
    };

    const setupPeopleNoField = () => {
      people_no_text_field.value = `${room_no.value} ${t("message.room")} - ${
        adult_no.value
      } ${t("message.adult")} - ${children_no.value} ${t("message.child")}`;
    };

    const closeNoModel = () => {
      peopleModel.value = false;
    };

    const peopleNoClick = () => {
      peopleModel.value = true;
    };
    const changeDate = () => {
      isDatePickerOpen.value = false;
      dateRange.value = dateToShow.value;
      formatDates();
      router.push({
        query: {
          governorate: governorate.value,
          checkin: checkin.value,
          checkout: checkout.value,
          room_no: room_no.value,
          adult_no: adult_no.value,
          children_no: children_no.value,
        },
      });
    };

    const formatDates = () => {
      if (!dateRange.value) {
        dateRange.value = [];
      }
      if (dateRange.value.length === 2) {
        let date = new Date(dateRange.value[0]);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        checkin.value = `${year}-${month}-${day}`;

        date = new Date(dateRange.value[1]);
        year = date.getFullYear();
        month = (date.getMonth() + 1).toString().padStart(2, "0");
        day = date.getDate().toString().padStart(2, "0");
        checkout.value = `${year}-${month}-${day}`;

        dateRangeFull.value = dateRange.value;
        dateToShow.value = [checkin.value, checkout.value];
      }
      if (
        dateRangeFull.value != dateRange.value &&
        dateRange.value.length != 0 &&
        dateRange.value.length != 1
      ) {
        dateRange.value = [];
        dateToShow.value = [checkin.value, checkout.value];
      }
    };

    watch(dateRange, () => {
      // formatDates();
    });

    const getHotels = async () => {
      try {
        await store.dispatch("getHotels", {
          take: 9,
          page: 1,
          governorate: governorate.value,
          checkin: checkin.value ? checkin.value : todayDate.value,
          checkout: checkout.value ? checkout.value : tomorrowDate.value,
          room_number: room_no.value ? room_no.value : 1,
          adult_number: adult_no.value ? adult_no.value : 1,
          children_number: children_no.value,
          currency: store.getters.currency_selected,
        });
        window.location.reload();
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    const search = () => {
      router.push({
        name: "Hotels",
        query: {
          governorate: governorate.value,
          checkin: checkin.value,
          checkout: checkout.value,
          room_no: room_no.value ? room_no.value : 1,
          adult_no: adult_no.value ? adult_no.value : 1,
          children_no: children_no.value,
        },
      });
      getHotels();
    };
    // Computed property to handle custom filtering
    const filteredItems = computed(() => {
      return searchFieldInputs.value;
    });
    // Watcher for searchFieldInputs to update filteredItems
    watch(searchFieldInputs, () => {
      filteredItems.value = searchFieldInputs.value;
    });

    return {
      dateRange,
      room_no,
      adult_no,
      children_no,
      peopleModel,
      people_no_text_field,
      dateToShow,
      disablePastDates,
      addOne,
      minusOne,
      setupPeopleNoField,
      disabledBeforeToday,
      closeNoModel,
      peopleNoClick,
      changeDate,
      search,
      getSearch: debouncedGetSearch, // Use debounced function here
      menu,
      governorates,
      governorate,
      checkin,
      checkout,
      todayDate,
      getDate,
      date,
      child_age,
      searchField,
      showSearch,
      getGovernorate,
      searchFieldInputs,
      locale,
      isRTL,
      t,
      filteredItems,
      disableDate,
      isDatePickerOpen,
    };
  },
};
</script>

<style scoped>
.conainter {
  font-family: "Cairo", sans-serif;
  position: relative;
  top: -70px;
  width: 70%;
  margin: auto;
  border-radius: 10px;
  align-items: flex-start;
  background-color: #fff;
  min-height: 100px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.content {
  z-index: 20;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
}
.input-fields {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: auto;
}
.right-field,
.center-field,
.left-field {
  z-index: 200;
  flex: 1;
}
.rtl-select .v-select__slot .v-select__placeholder {
  text-align: right !important;
  direction: rtl !important;
}
.btn-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-content: center;
  padding-bottom: 3rem;
  padding-right: 2rem;
}
.btn-div-rtl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-content: center;
  padding-bottom: 3rem;
  padding-left: 2rem;
}
button {
  background-color: #003b7d;
}
@media (max-width: 1000px) {
  .conainter {
    width: 80%;
    min-height: 500px;
    gap: 60px;
  }
  .input-fields {
    display: block;
  }
  .right-field,
  .center-field,
  .left-field {
    z-index: 200;
    flex: 100%;
  }
}
@media (max-width: 1280px) {
  .conainter {
    width: 100%;
    height: 150px;
    gap: 5px;
  }
  .input-fields {
    display: block;
  }
  .right-field,
  .center-field,
  .left-field {
    z-index: 200;
    flex: 100%;
  }
  .search-text {
    display: block;
    margin-top: 1rem;
  }
}
.center-field {
  position: relative;
}
.people-card {
  position: absolute;
  width: 120%;
  top: 100px;
}

@media (max-width: 1200px) {
  .people-card {
    position: absolute;
    width: 100%;
  }
}
.input-number {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;
  justify-content: space-around;
}
@media (max-width: 1350px) {
  .input-number {
    font-size: 13px;
  }
}

/* Dark Mode */
.dp__theme_dark {
  --dp-border-color: #aaaeb7;
  --dp-menu-border-color: #aaaeb7;
  --dp-border-color-hover: #000;
}

/* Light Mode */
.dp__theme_light {
  --dp-border-color: #aaaeb7;
  --dp-menu-border-color: #aaaeb7;
  --dp-border-color-hover: #000;
}

/* Custom class to apply the theme */
.custom-date-picker {
  --dp-font-size: 1rem;
  --dp-preview-font-size: 0.8rem;
  --dp-time-font-size: 0.8rem;
  --dp-action-buttons-padding: 12px 15px;
}
</style>

<style lang="scss">
$default-color: #000;
$primary-color: #013b7d;
.mx-btn-icon-double-right,
.mx-btn-icon-double-left {
  display: none !important;
}
.mx-calendar,
.mx-datepicker-popup * {
  font-family: "Cairo", sans-serif;
}
.mx-input {
  padding: 19px 30px !important;
  border-color: #afb5bd !important;
}
.mx-btn-current-year,
.mx-btn-current-month {
  font-weight: bold !important;
}
.mx-datepicker-range {
  width: 100% !important;
}
@import "~vue-datepicker-next/scss/index.scss";
</style>
