<template>
  <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'card-container', 'shadow', 'hover:bg-gray-100 bg-white', 'w-full']" style="width: 100%;">
    <div class="image-container">
      <div class="image-text rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <span><span class="city-text">{{ locale === 'ar' || locale === 'kr' ? hotel.governorate.ar_name : hotel.governorate.en_name }}</span></span>
      </div>
      <div class="image-icons rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <span class="rounded-full" @click.stop="toggleFavorite(hotel)">
          <v-icon
            size="20"
            :class="hotel.is_faviorate ? 'text-orange' : 'text-gray'"
            :icon="hotel.is_faviorate ? 'mdi-star' : 'mdi-star-outline'"
          ></v-icon>
        </span>
      </div>
      <img :src="hotel.image" alt="" />
    </div>
    <div class="card-text">
      <div class="header-text-container">
        <div style="display: flex">
          <h1 style="font-weight: 800; font-size: larger" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
            {{ hotel.title }}
          </h1>
          <span class="font-medium rounded-full text-center start-count mx-2">
            <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon>
            <span>{{ hotel.stars }}</span>
          </span>
        </div>
        <div>
          <v-icon
            class="text-orange"
            v-for="(item, index) in hotel.stars"
            :key="index"
            size="14"
            icon="mdi-star"
          ></v-icon>
          <span class="rating-count">{{ hotel.stars }}/5</span>
        </div>
      </div>
      <div style="display: flex; gap: 20px">
        <span v-if="locale === 'ar' || locale === 'kr'" style="color: #888">{{ hotel.governorate.ar_name }} \ {{ hotel.city.ar_name }}</span>
        <span v-else style="color: #888">{{ hotel.governorate.en_name }} \ {{ hotel.city.en_name }}</span>
      </div>
      <span class="rating-count" v-if="hotel.ar_description" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        {{ locale === 'ar' || locale === 'kr' ? hotel.ar_description.slice(0, 255) : hotel.en_description.slice(0, 255) }}...
      </span>
      <span class="rating-count" v-else>{{ $t("message.noDescription") }}</span>
      <div style="flex-wrap: wrap; gap: 20px" class="hidden md:flex">
        <span
          v-for="(item, index) in hotel.all_services.slice(0, 10)"
          :key="index"
          style="background-color: #fafafa; font-size: 16px"
          class="stars-line rounded-full text-center px-4 text-blue-600"
        >
          <i :class="item.icon"></i>
          <span class="mx-3">{{ locale === 'ar' || locale === 'kr' ? item.ar_name : item.en_name }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  props: {
    hotel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const store = useStore();

    const toggleFavorite = async (hotel) => {
      try {
        await store.dispatch("addOrRemoveFavorite", { hotel_id: hotel.id });
        hotel.is_faviorate = !hotel.is_faviorate;
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    };

    return { locale, toggleFavorite };
  },
};
</script>
<style scoped>
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 850px;
  border-radius: 10px;
}

@media (max-width: 1670px) {
  .card-container {
    width: 750px;
  }
}
@media (max-width: 1480px) {
  .card-container {
    width: 700px;
  }
}
@media (max-width: 1380px) {
  .card-container {
    width: 550px;
  }
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  justify-content: space-between;
  padding: 10px 15px 15px 15px;
  gap: 18px;
  width: 100%;
}
.image-container {
  height: 270px;
  width: 300px;
  margin: 5px;
  position: relative;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}

.header-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.start-count {
  font-size: 16px;
  background-color: #fafafa;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 1px;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.image-text {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 12px;
  margin: 5px;
  font-size: 12px;
}
.image-icons {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  margin: 5px;
  font-size: 12px;
  display: flex;
  gap: 4px;
}
.image-icons span {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px;
}
.city-text {
  color: #ffb325;
}
.text-gray {
  color: gray;
}
</style>
